jQuery(document).ready(function(){

  // Flag to check if the page has already been set to refresh
  var pageAlreadySetToRefresh = false;

  function checkSuccessMessage() {
      var successMessage = jQuery('#mc_embed_signup #mce-success-response');
      
      if (successMessage.length > 0 && successMessage.css('display') === 'block' && !pageAlreadySetToRefresh) {
          pageAlreadySetToRefresh = true; // Set the flag to true to prevent further refreshes
          setTimeout(function() {
              window.location.reload();
          }, 3000); // 3000 milliseconds = 3 seconds
      }
  }

  // Call the function initially
  checkSuccessMessage();
  
  // Set an interval to check periodically
  setInterval(checkSuccessMessage, 500);
});
